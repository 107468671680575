<template>
    <div v-if="content" class="my-auto">
        <div v-for="photo in content.photos">
            <photo :photo="photo" />
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Photo from '../components/Photo.vue';

export default {

    components: {
        Photo
    },

    computed: {

        ...mapState(['content'])

    },

    mounted() {
        this.loadData();
    },

    methods: {

        ...mapActions(['fetchData']),

        loadData() {
            this.fetchData();
        }

    }
}
</script>