<template>
    <div class="h-screen flex flex-col">
        <div v-if="content?.latestRecord" class="relative overflow-hidden pt-[70vh]">
            <iframe class="absolute top-0 left-0 w-full h-full"
                    :src="content?.latestRecord.videoUrl"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
            </iframe>
        </div>
        <div class="container mx-auto my-auto">
            <div v-if="content?.latestRecord">
                <p v-html="content?.latestRecord.content" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {

    computed: {

        ...mapState(['content'])

    },

    mounted() {
        this.loadData();
    },

    methods: {

        ...mapActions(['fetchData']),

        loadData() {
            this.fetchData();
        }

    }
}
</script>