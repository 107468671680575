<template>
    <div class="container mx-auto flex justify-center">
        <div v-if="content?.who" class="content max-w-6xl">
            <img class="fixed z-0 -bottom-8 md:bottom-0 -right-10 max-w-[30vh] md:max-w-[300px]" :src="content.who.image.src" />
            <div v-html="content.who.content" />
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Photo from "@/components/Photo.vue";

export default {
    components: {Photo},

    computed: {

        ...mapState(['content'])

    },

    mounted() {
        this.loadData();
    },

    methods: {

        ...mapActions(['fetchData']),

        loadData() {
            this.fetchData();
        }

    }
}
</script>