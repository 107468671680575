import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/Home.vue';
import Who from '../pages/Who.vue';
import Pictures from '../pages/Pictures.vue';
import About from '../pages/About.vue';
import LatestRecord from '../pages/LatestRecord.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/who',
    name: 'Who',
    component: Who,
    meta: { title: 'soundbarn - who?' },
  },
  {
    path: '/pictures',
    name: 'Pictures',
    component: Pictures,
    meta: { title: 'soundbarn - pictures' },
  },
  {
    path: '/latest-record',
    name: 'Latest Record',
    component: LatestRecord,
    meta: { title: 'soundbarn - latest record' },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'soundbarn - about & gear' },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'soundbarn - recording and mixing studio';
  store.commit('closeMenu');
  next();
})

export default router;