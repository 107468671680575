<template>
    <Menu />
    <div id="app" class="min-h-screen bg-black text-white" v-if="!menuOpen">
        <router-view />
    </div>
</template>

<script>
import Menu from './components/Menu.vue';
import {mapState} from "vuex";

export default {

    components: {
        Menu
    },

    computed: {

        ...mapState(['menuOpen'])

    }
}
</script>