<template>
    <div class="container mx-auto flex justify-center">
        <div v-if="content?.about" class="content">
            <div v-html="content?.about.content" class="mb-16 xl:mb-32" />
            <div v-for="(category, index) in content.about.gear" class="mt-12">
                <div v-html="index" class="mb-2" />
                <ul class="text-sm">
                    <li v-for="item in category" v-html="item" />
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {

    computed: {

        ...mapState(['content'])

    },

    mounted() {
        this.loadData();
    },

    methods: {

        ...mapActions(['fetchData']),

        loadData() {
            this.fetchData();
        }

    }
}
</script>