<template>
    <nav>
        <button
            class="fixed right-8 top-8 md:top-10 md:right-10 xl:top-16 xl:right-16 text-6xl w-[46px] md:w-[52px] xl:w-[76px] z-30 outline-0"
            @click="toggleMenu"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36.14355 31.14901"
                :class="{
                    'fill-white' : !menuOpen,
                    'fill-black' : menuOpen,
                }"
            >
                <path class="cls-1" d="M11.9043.14355h12.38379c1.53613,0,3.66406-.03125,6.38379-.0957.86426-.03223,1.48828-.04785,1.87207-.04785,2.40039,0,3.59961,1.02441,3.59961,3.07129,0,2.24023-1.19922,3.36035-3.59961,3.36035-.44824,0-1.07227-.01562-1.87207-.04785-1.28027-.06348-3.4082-.1123-6.38379-.14453h-12.38379c-2.97656.03223-5.12012.08105-6.43262.14453-.83203.03223-1.45605.04785-1.87207.04785-2.39941,0-3.59961-1.02344-3.59961-3.07227C0,2.14355.27148,1.28027.81641.76758c.54297-.51172,1.4707-.76758,2.7832-.76758.38477,0,1.00879.01562,1.87207.04785,1.18359.03223,3.32812.06445,6.43262.0957Z"/>
                <path class="cls-1" d="M11.9043,12.50224h12.38379c1.53613,0,3.66406-.03125,6.38379-.0957.86426-.03223,1.48828-.04785,1.87207-.04785,2.40039,0,3.59961,1.02441,3.59961,3.07129,0,2.24023-1.19922,3.36035-3.59961,3.36035-.44824,0-1.07227-.01562-1.87207-.04785-1.28027-.06348-3.4082-.1123-6.38379-.14453h-12.38379c-2.97656.03223-5.12012.08105-6.43262.14453-.83203.03223-1.45605.04785-1.87207.04785-2.39941,0-3.59961-1.02344-3.59961-3.07227,0-1.21582.27148-2.0791.81641-2.5918.54297-.51172,1.4707-.76758,2.7832-.76758.38477,0,1.00879.01562,1.87207.04785,1.18359.03223,3.32812.06445,6.43262.0957Z"/>
                <path class="cls-1" d="M11.9043,24.86093h12.38379c1.53613,0,3.66406-.03125,6.38379-.0957.86426-.03223,1.48828-.04785,1.87207-.04785,2.40039,0,3.59961,1.02441,3.59961,3.07129,0,2.24023-1.19922,3.36035-3.59961,3.36035-.44824,0-1.07227-.01562-1.87207-.04785-1.28027-.06348-3.4082-.1123-6.38379-.14453h-12.38379c-2.97656.03223-5.12012.08105-6.43262.14453-.83203.03223-1.45605.04785-1.87207.04785-2.39941,0-3.59961-1.02344-3.59961-3.07227,0-1.21582.27148-2.0791.81641-2.5918.54297-.51172,1.4707-.76758,2.7832-.76758.38477,0,1.00879.01562,1.87207.04785,1.18359.03223,3.32812.06445,6.43262.0957Z"/>
            </svg>
        </button>
        <div
            :class="{'flex': menuOpen, 'hidden': !menuOpen}"
            class="flex-col w-full min-h-screen absolute bg-white text-5xl z-20 pb-20"
        >
            <div class="mt-[20vh]">
                <div v-for="item in menu" class="text-black px-4 py-3">
                    <router-link
                        v-if="item.route"
                        :to="item.route"
                        @click.native="closeMenu"
                        v-text="item.name"
                    />
                    <a v-else href="" v-text="item.name" />
                </div>
            </div>

            <router-link
                to="/"
                @click.native="closeMenu"
            >
                <logo :inverted="true" />
            </router-link>

        </div>
    </nav>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Logo from "@/components/Logo.vue";

export default {
    components: {Logo},

    methods: {

        ...mapMutations(['toggleMenu', 'closeMenu'])

    },

    computed: {

        ...mapState(['menu', 'menuOpen'])

    }

}
</script>