import {createStore} from 'vuex'

export default createStore({

    state: {
        menu: null,
        content: null,
        menuOpen: true
    },

    mutations: {

        setMenu(state, payload) {
            state.menu = payload
        },

        setContent(state, payload) {
            state.content = payload
        },

        toggleMenu(state) {
            state.menuOpen = !state.menuOpen
        },

        closeMenu(state) {
            state.menuOpen = false
        }

    },

    actions: {
        fetchData({commit, state}) {
            if (!state.menu || !state.content) {
                fetch('/data.json')
                    .then(response => response.json())
                    .then(data => {
                        commit('setMenu', data.menu)
                        commit('setContent', data.content)
                    })
            }
        }
    }

})