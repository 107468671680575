<template>

    <picture>
        <source media="(min-width: 1024px)" :srcset="photo.src.large">
        <source media="(min-width: 768px)" :srcset="photo.src.medium">
        <source media="(max-width: 767px)" :srcset="photo.src.small">
        <img
            :src="photo.src.large"
            :alt="photo.title"
            class="border-b-8 border-black w-full object-cover"
            :style="{
                        'object-position': photo.position ?? 'center',
                        'max-height': photo.maxHeight ?? '100vh'
                    }"
        />
    </picture>

</template>
<script>
export default {

    props: {
        photo: {
            type: Object,
            required: true
        }
    }

}
</script>