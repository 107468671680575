<template>
    <div class="pt-20 w-full md:fixed md:bottom-20 xl:bottom-32 flex-1">
        <div class="mx-auto max-w-[56vw] md:max-w-[25vw] xl:max-w-[300px]">
            <img class="w-full" :src="src" />
        </div>
    </div>
</template>
<script>
export default {

    props: ['inverted'],

    computed: {

        src() {
            return this.inverted ? '/images/soundbarn-logo-inverted.svg' : '/images/soundbarn-logo.svg'
        }

    }

}
</script>