<template>
    <div class="container mx-auto flex justify-center min-h-screen">
        <div v-if="content" class="content mb-0 flex flex-col pb-20 items-center">
            <div class="md:max-w-[920px] xl:max-w-[1100px]" v-html="content?.home" />
            <logo />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Logo from '@/components/Logo.vue';

export default {

    components: {
        Logo
    },

    computed: {

        ...mapState(['content'])

    },

    mounted() {
        this.loadData();
    },

    methods: {

        ...mapActions(['fetchData']),

        loadData() {
            this.fetchData();
        }

    }
}
</script>